// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

.accordion {
  --#{$prefix}accordion-color: #{$accordion-color};
}

.accordion-button.collapsed svg {
  fill: $accordion-button-color;
}
.accordion-button:not(.collapsed) svg {
  fill: $accordion-button-active-color;
}

.accordion-nav {
  flex-direction: column;
  flex-shrink: 0;

  @include media-breakpoint-up(md) {
    & + .accordion-flush .accordion-item {
      border: none;
    }
  }

  > .accordion-button {
    border: 1px solid $accordion-border-color;
    border-left: none;
    text-align: left;

    &:focus {
      border-color: $accordion-border-color;
      box-shadow: none;
    }

    &.collapsed {
      border-bottom: 1px solid transparent;
    }

    &:not(.collapsed) {
      border-color: $primary;
      border-radius: $border-radius;
      box-shadow: none !important;
    }

    &:after {
      border-color: transparent transparent transparent $primary;
      border-style: solid;
      border-width: 2rem 0 2rem 1rem;
      content: "";
      position: absolute;
      right: -1.25rem;
      transition: all 0.2s ease-in-out;
      background-image: none;
      transform: none;
    }

    &.collapsed:after {
      border-width: 0;
      content: "";
      transition: all 0.2s ease-in-out;
    }
  }
}
