// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

$navbar-anchor-offset: 5rem !default;
$navbar-menu-height: 15rem !default;
$navbar-height: (
    $font-size-base * $line-height-base + $nav-link-padding-y * 2
    + $navbar-padding-y * 2);
$navbar-height-expanded: $navbar-height + $navbar-menu-height;

.mb-navbar {
  margin-bottom: calc(#{$navbar-height-expanded * -1} - 1px);
}

@include media-breakpoint-up(md) {
  .mb-navbar {
    margin-bottom: calc(#{$navbar-height * -1} - 1px);
  }
}

.pt-navbar {
  padding-top: calc(#{$navbar-height-expanded} + 1px);
}

@include media-breakpoint-up(md) {
  .pt-navbar {
    padding-top: calc(#{$navbar-height} + 1px);
  }
}

.navbar-light {
  .nav-icon {
    fill: $navbar-light-color;
    &:hover {
      fill: $navbar-light-hover-color;
    }
  }

  .navbar-brand .nav-icon {
    fill: $navbar-light-brand-color;
    &:hover {
      fill: $navbar-light-brand-hover-color;
    }
  }
}

// Allow inserting anchors so following content appears below the navbar
.anchor {
  display: block;
  position: relative;
  top: $navbar-anchor-offset * -1;
}
