// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

$tiny-font-size: .5em !default;
$tiny-font-weight: $font-weight-normal !default;

.tiny {
  @include font-size($tiny-font-size);
  font-weight: $tiny-font-weight;
}
