// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

$toc-top-offset: 5rem !default;

.toc {
  position: sticky;
  top: $toc-top-offset;

  > .toc-list {
    border-left: 1px solid $border-color;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    flex-direction: column;

    @include media-breakpoint-only(xl) {
      display: flex;
      width: calc(50vw - 25rem);
    }

    @include media-breakpoint-only(xxl) {
      display: flex;
      width: calc(50vw - 29rem);
    }
  }
}

.toc-list {
  list-style: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  .toc-list {
    padding: 0 0 0.5rem 0.5rem;
  }
}

.toc-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
