// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

// AOS variables
$aos-distance: 2rem;


// Colours
$body-color: #435160;
$headings-color: #223344;
$primary: #304961;
$secondary: #B71554;
$light: #E9EAEC;
$dark: #223344;

$black: #000;
$gray-500: #adb5bd;
$white: #FFF;

$text-muted: $gray-500;

$link-color: $secondary;
$link-decoration: none;

$accordion-button-active-bg: $primary;
$accordion-button-active-color: $white;

$input-placeholder-color: $gray-500;

$navbar-light-color: $body-color;
$navbar-light-hover-color: darken($body-color, 50%);
$navbar-light-active-color: darken($body-color, 50%);


// Font Sizes and Styles
$font-family-sans-serif: Questrial, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.8;

$small-font-size: .8em;
$tiny-font-size: .5em;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$display-font-sizes: (
  1: 4.5rem,
  2: 3.5rem,
  3: 2.75rem,
  4: 2.25rem,
  5: 1.75rem,
  6: 1.25rem
);

$headings-font-weight: 500;

$breadcrumb-font-size: $font-size-sm;


// Border Styles
$border-radius: 0.25rem;


// Spacing
$paragraph-margin-bottom: 0.5rem;

$breadcrumb-item-padding-x: .25rem;

$navbar-padding-y: 0.25rem;

$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;
