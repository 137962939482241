// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

$backgrounds: (
  about-open-source-software: "measure.jpg",
  cloud-services: "cloud.jpg",
  contact-us: "contact.jpg",
  cookie-policy: "cookies.jpg",
  legal-information: "legal.jpg",
  libateq: "work.jpg",
  open-source: "freedom.jpg",
  open-source-applications: "applications.jpg",
  privacy-policy: "privacy.jpg",
  samba: "samba.jpg",
  services: "services.jpg",
  secondary: "secondary.jpg",
  site-map: "compass.jpg",
  support: "support.jpg",
  support-and-maintenance: "support.jpg",
  system-development: "system-development.jpg",
  tryton: "tryton.jpg",
  what-is-source-code: "code.jpg",
);

$overlays: (
  contact: linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba(215, 196, 198, 0.8) 100%),
  legal: linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba(190, 191, 218, 0.8) 100%),
  open-source: linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba(200, 201, 203, 0.8) 100%),
  services: linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba(190, 191, 218, 0.8) 100%),
  site: linear-gradient(to bottom, rgba($white, 0.9) 0, rgba(200, 201, 203, 0.8) 100%),
  support: linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba(190, 216, 193, 0.8) 100%),
  tryton: linear-gradient(to bottom, rgba($white, 0.9) 0%, rgba(200, 201, 203, 0.8) 100%),
);

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@each $name, $file in $backgrounds {
  .bg-#{$name} {
    background-image: url("/image/background/#{$file}");
  }
}

@each $name, $overlay in $overlays {
  .overlay-#{$name} {
    background: #{$overlay};
  }
}

// Add bg-image to bg-secondary
.bg-secondary {
  @extend .bg-image
}
