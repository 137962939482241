// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

@import "aos/src/sass/aos";

html:not(.aos-enable) [data-aos] {
  opacity: 1;
  transform: none;
  transition-property: none;
}
