// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

$locations: (
  start: left,
  center: center,
  end: right,
);

@each $name, $position in $locations {
  .object-#{$name} {
    object-fit: cover;
    object-position: #{$position};
  }
}
