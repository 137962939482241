// This file is part of the website-static-libateq package.
// Please see the COPYRIGHT and README.rst files at the top level of this
// package for copyright notices and further information.

#label-register-password-repeat,
#field-register-password-repeat {
  display: none;
}

#label-contact-subject,
#field-contact-subject {
  display: none;
}
